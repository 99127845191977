import React, { useEffect, useState } from "react";

import noq from "../assets/noqog.png";
import Socials from "../components/socials";

const title = "entry.674154541";
const artist = "entry.1987401029";
const message = "entry.79666669";
const name = "entry.909401924";

const initialState = {
  [title]: "",
  [artist]: "",
  [message]: "",
  [name]: "",
};
export default function Bnpp() {
  const [showSuccess, setshowSuccess] = useState(false);
  const [form, setForm] = useState(initialState);
  const [error, setError] = useState();

  const submitForm = async (e) => {
    e.preventDefault();

    if (form[title] === "") {
      return setError("Please enter a song title!");
    }

    const formLink =
      "https://docs.google.com/forms/d/e/1FAIpQLScyfnkVhJeGSBF4k8_knC1U07rh89rtj9wCWhPBGNYf-HRSow/formResponse?&submit=Submit?usp=pp_url";

    const params = new URLSearchParams(form);
    const submitUrl = new URL(`${formLink}&${params}`);
    await fetch(submitUrl, { mode: "no-cors" });

    setshowSuccess(true);
    setForm(initialState);
    setError(null);
  };

  const handleChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    let interval;
    if (showSuccess) {
      interval = setTimeout(() => {
        setshowSuccess(false);
      }, 5000);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [showSuccess]);

  return (
    <div className="container mx-auto items-center mt-4 h-full px-4 max-w-4xl">
      <div className="flex-row justify-center justify-items-center md:mt-5 lg:w-full">
        <a href="https://noqevents.live" target="_blank" rel="noreferrer">
          <img
            src={noq}
            alt="noq events logo"
            className="w-5/6 md:w-1/3 mt-2 mx-auto md:inline md:ml-2"
          />
        </a>
      </div>
      <p className="text-xl lg:text-2xl my-8 text-slate-600 ">
        Send us your song requests
        {showSuccess ? (
          <span className="rounded-full text-noq ml-2 text-sm font-extrabold">
            We got your request! Send in more!
          </span>
        ) : (
          <></>
        )}
      </p>
      <div className="shadow-md p-4 border-2 rounded-md border-slate-200 border-solid">
        <form onSubmit={submitForm}>
          <label htmlFor="song title" className="block mt-2">
            <span className="text-gray-700 text-base font-medium mr-1">
              Song Title:
            </span>
            <span className="text-red-600 text-base mr-1">
              {error ? error : ""}
            </span>
            <input
              type="text"
              className={`
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-gray-100
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                      ${error ? "border-red-600 focus:border-red-600" : ""}
                      `}
              name="entry.674154541"
              value={form[title]}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="song title" className="block mt-2">
            <span className="text-gray-700 text-base font-medium">Artist:</span>
            <input
              type="text"
              className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-gray-100
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
              name="entry.1987401029"
              value={form[artist]}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="song title" className="block mt-2">
            <span className="text-gray-700 text-base font-medium">
              Your Name:
            </span>
            <input
              type="text"
              className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-gray-100
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
              name="entry.909401924"
              value={form[name]}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="song title" className="block mt-2">
            <span className="text-gray-700 text-base font-medium">
              Message:
            </span>
            <textarea
              className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-gray-100
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
              name="entry.79666669"
              value={form[message]}
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            className="rounded-full bg-noq hover:text-slate-100 px-5 py-2 mt-4 w-full font-extrabold text-white my-2"
          >
            Submit
          </button>
        </form>
      </div>
      <Socials />
    </div>
  );
}
